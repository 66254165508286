import React from "react";
import PropTypes from 'prop-types'

const CapabilitiesList = ({ capabilities, noLinkOffs }) => {
  return (
    <div className="capabilities__list container">
      {capabilities.map((capability, i) => (
        <React.Fragment key={i}>
          <h3 className={`capability__name ${noLinkOffs ? 'capability__name--noArrows' : ''}`}>{capability}</h3>
          <div className="vertical-divider"></div>
        </React.Fragment>
      ))}
    </div>
  )
}

CapabilitiesList.propTypes = {
  capabilities: PropTypes.array.isRequired,
  noLinkOffs: PropTypes.bool
}

export default CapabilitiesList