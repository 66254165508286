import * as React from "react";
import Headroom from "react-headroom";
// import classNames from "classnames";
// import MullenLoweLogo from '../images/svg/mullenlowe-logo.svg'
import MullenLowePRLogo from "../images/svg/MLPR_LOGO_WHITE.svg";

const Header = ({ scheme }) => {
  return (
    <Headroom upTolerance={10} disableInlineStyles={true}>
      <header className="header header__dark container">
        <div className={`header__ml-logo`}>
          <MullenLowePRLogo />
        </div>
      </header>
    </Headroom>
  );
};

export default Header;
