import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Keyboard } from "swiper";

// swiper bundle styles
import "swiper/css/bundle";
// swiper core styles
import "swiper/css";
// modules styles
import "swiper/css/navigation";
import "swiper/css/pagination";


const CapabilitiesSwiper = ({ capabilities, noLinkOffs }) => {
  return (
    <div className="capabilities-swiper__wrap">
      <Swiper
      speed={800}
      className="capabilities-swiper" 
      modules={[Navigation, Keyboard]}
      slidesPerView={'auto'}
      keyboard={true}
      navigation={ {clickable: true}  }
      spaceBetween={40}
      observer={true}
      observeParents={true}
      breakpoints={{
        744: {
          spaceBetween: 100,
        },
        1024: {
          spaceBetween: 120
        },
        1200: {
          spaceBetween: 140
        } 
      }}
      >
        {capabilities.map((capability, i) => (
          <SwiperSlide
            key={i} 
            className="capability__slide"
          >
            <h3 className={`capability__name ${noLinkOffs ? 'capability__name--noArrows' : ''}`}>{capability}</h3>
          </SwiperSlide>
        ))}
      
      </Swiper>
    </div>
  );
};

export default CapabilitiesSwiper;
