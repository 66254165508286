import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutIntro from "../components/aboutIntro"
import Capabilities from "../components/capabilities"
import ClientList from "../components/clientList"
import InlineVid from "../components/inlineVid"
import PrContact from "../components/prContact"


const IndexPage = ({ location, data }) => {
  const inlineVideo = data.sanityPrPage.inlineVideo;

  return (
    <Layout scheme="dark" location={location}>
      <Seo title="MullenLowe U.S. Public Relations" />
      <AboutIntro copy={data.sanityPrPage._rawIntroCopy} />
      <InlineVid 
        vimeoID={inlineVideo.vimeoID}
        ctaPlacement={inlineVideo.CtaPlacement}
        ctaColor={inlineVideo.CtaColor}
        cta={inlineVideo.cta}
        coverImage={inlineVideo.coverImage.image}
        altText={inlineVideo.coverImage.altText}
      />
      <Capabilities capabilities={data.sanityPrPage.capabilities} borderBottom borderTop noLinkOffs />
      <ClientList clients={data.sanityPrPage.clients} />
      <PrContact
        cta={data.sanityPrPage.cta} 
        email={data.sanityPrPage.contactEmail}
        borderTop 
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    sanityPrPage {
      _rawIntroCopy
      capabilities
      contactEmail
      inlineVideo {
        vimeoID
        CtaPlacement
        CtaColor
        cta
        coverImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
      clients {
        name
        id
        logo {
          asset {
            url
            gatsbyImageData(placeholder: NONE)
          }
        }
      } 
    }
  }
`