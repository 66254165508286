import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const PrContact = ({ ...props }) => {
  return (
    <section
      className={classNames("client-section container", {
        "border-bottom": props.borderBottom,
        "border-top": props.borderTop,
      })}
      aria-label="call to action items">
      <h2 className="descriptor">{props.cta}</h2>
      <a className="descriptor descriptor-link" href={`mailto:${props.email}`}>
        Get in touch
      </a>
      <a
        className="descriptor descriptor-link"
        href="https://boards.greenhouse.io/mullenlowe"
        target="_blank"
        rel="noreferrer">
        Check out our open roles
      </a>
    </section>
  );
};

PrContact.defaultProps = {
  cta: "Curious about what an earned edge could do for your brand?",
};

PrContact.propTypes = {
  cta: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
};

export default PrContact;
