import * as React from "react"
import PropTypes from "prop-types"
import CookieConsent, { Cookies } from "react-cookie-consent";
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import "../styles/layout.scss"
import Header from "./header"


const Layout = ({ 
  children, 
  scheme,
  location, 
}) => {

  return (
    <>
      <Header scheme="dark"/>
      <main className={scheme}>
        <div>
          {children}
        </div>
      </main>
       
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        hideOnAccept="true"
        onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', true)
          Cookies.set('gatsby-gdpr-linkedin', true)
          initializeAndTrack(location)
        }}
        containerClasses={`cookie-consent light`}
        buttonWrapperClasses="cookie-consent__btns"
        contentClasses="cookie-consent__text"
        enableDeclineButton
      >
      We use cookies and other tracking technologies on our website and to understand where our anonymous visitors are coming from.
      </CookieConsent>
      
    </>
  )
}

Layout.propTypes = {
  scheme: PropTypes.string,
}

Layout.defaultProps = {
  scheme: 'dark',
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
