import * as React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { Fade } from "react-awesome-reveal";
import classNames from 'classnames';
import PropTypes from "prop-types"

const ClientList = ({ clients, borderBottom, borderTop }) => {
  return (
    <section
      aria-labelledby='clients' 
      className={classNames(
        "client-section container",
        {
          'border-bottom': borderBottom,
          'border-top': borderTop
        })} 
    >
      <h2 id="clients" className="descriptor">Clients</h2>
      <div className="client-list">
        {clients.map(client => {
          return (
            <div
              className="client" 
              key={client.id} 
              id={client.id}
            >
              <Fade delay={1}>
                <ClientLogo client={client} />
              </Fade>
            </div>
          )
        })}
      </div>
    </section>
  )
}

const ClientLogo = ({ client }) => (
  <GatsbyImage 
    className="client-logo" 
    image={client.logo?.asset.gatsbyImageData} 
    alt={`${client.name} logo`}
  />
)

ClientList.propTypes = {
  clients: PropTypes.array,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool
}

export default ClientList