import * as React from "react"
import { useState, useRef, useEffect } from "react";
import Vimeo from '@u-wave/react-vimeo';
import VideoOverlayCta from "./workDetail/videoOverlayCta";
import SanityImage from "gatsby-plugin-sanity-image";
import PropTypes from "prop-types"

const InlineVid = ({ ...props }) => {
  const handleClick = () => {
    setPlaying(true);
    player.current.player.play();
  }

  const player = useRef(null);
  const vid = useRef(null);
  const [playing, setPlaying] = useState(false);
  const onClickOutside = () => setPlaying(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (vid.current && !vid.current.contains(event.target)) {
        player.current.player.pause();
        onClickOutside && onClickOutside(event);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[])

  return (
    <div className="container inline-vid capabilities">
      
      <div className="video" ref={vid}>
        <Vimeo
          ref={player}
          video={props.vimeoID}
          className="inline-vid__iframe"
          textTrack="en-x-autogenerated"
          onEnd={() => setPlaying(false)}
          playsInline={false}
        />

        {!playing && (
          <div className="inline-vid__cover-image">
            <SanityImage {...props.coverImage} alt={props.altText || ''} />
            <VideoOverlayCta
              ctaColor={props.ctaColor}
              ctaPlacement={props.ctaPlacement}
              handleClick={handleClick}
            >
              {props.cta}
            </VideoOverlayCta>  
          </div>
        )}
      </div>
    </div>
  )
}

InlineVid.defaultProps = {
  cta: 'play reel',
  ctaColor: '#fff',
  ctaPlacement: "bottomLeft"
}

InlineVid.propTypes = {
  vimeoID: PropTypes.string.isRequired,
  cta: PropTypes.string,
  ctaPlacement: PropTypes.string,
  ctaColor: PropTypes.string,
  coverImage: PropTypes.object.isRequired,
  altText: PropTypes.string,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool
}

export default InlineVid

