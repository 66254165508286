import * as React from "react";
import { useState, useEffect } from "react";
import CapabilitiesSwiper from "./capbilitiesSwiper";
import CapabilitiesList from "./capabilitiesList";
import classNames from "classnames";
import PropTypes from "prop-types";

const Capabilities = ({ ...props }) => {
  const [makeSwiper, setMakeSwiper] = useState(true);

  useEffect(() => {
    const breakpoint = window.matchMedia("(min-width: 1440px)");

    const breakpointChecker = () =>
      breakpoint.matches && props.capabilities.length < 5
        ? setMakeSwiper(false)
        : setMakeSwiper(true);

    breakpoint.addEventListener("change", breakpointChecker);
    breakpointChecker();

    return () => breakpoint.removeEventListener("change", breakpointChecker);
  }, [makeSwiper, props.capabilities.length]);

  return (
    <section
      className={classNames("capabilities", {
        "capabilities--other": props.otherCapabilities,
        "border-bottom": props.borderBottom,
        "border-top": props.borderTop,
      })}
      aria-labelledby="capabilities">
      <h2 id="capabilities" className="container descriptor">
        Capabilities
      </h2>

      {!makeSwiper && (
        <CapabilitiesList
          capabilities={props.capabilities}
          noLinkOffs={props.noLinkOffs}
        />
      )}

      {makeSwiper && (
        <CapabilitiesSwiper
          capabilities={props.capabilities}
          noLinkOffs={props.noLinkOffs}
        />
      )}
    </section>
  );
};

Capabilities.propTypes = {
  capabilities: PropTypes.array.isRequired,
  otherCapabilities: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderTop: PropTypes.bool,
};

export default Capabilities;
